<template>
  <dialog-outline v-model="showDialog" :display-progress="displayProgress">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <task-dialog-header :task="task"></task-dialog-header>

      <v-divider></v-divider>

      <!-- form (hookup any form validation if/when needed) -->
      <v-form v-model="isValidForm">
        <v-container>
          <!-- Task User -->
          <v-select
            v-model="selectedUser"
            class="mt-2"
            dense
            item-text="name"
            item-value="id"
            :items="userList"
            :label="userLabel"
            :hint="userHint"
            :rules="userSelectionRequired"
          ></v-select>

          <!-- Comments -->
          <v-textarea
            hide-details
            :label="commentLabel"
            v-model="comment"
            outlined
          ></v-textarea>

          <!-- Notification Email -->
          <v-checkbox
            dense
            hide-details
            v-model="sendNotification"
            :label="notificationLabel"
          ></v-checkbox>
        </v-container>
      </v-form>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-tooltip-button @click="onOk" :disabled="!isValidForm">
        <template v-slot:default>
          {{ dialogOkCommandLabel }}
        </template>
        <template v-slot:toolTip>
          {{ dialogOkCommandTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-tooltip-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
        <template v-slot:toolTip>
          {{ dialogCancelCommandTooltip }}
        </template>
      </base-tooltip-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findUserTaskCommand,
  userTaskCommandType
} from "@/model/workflow/task/taskModel";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("inbox");

// mixins
import { userTaskAssignmentMixin } from "@/mixins/shared/workflow/task/userTaskAssignmentMixin";

export default {
  name: "AssignUserDialog",
  mixins: [userTaskAssignmentMixin],
  data() {
    return {
      /**
       * selected User
       */
      selectedUser: undefined,

      /**
       * user List
       */
      userList: []
    };
  },
  computed: {
    /**
     * overwrite userTaskAssignmentMixin.command
     * @type {{type: number, label: string, icon: string, description: string}}
     */
    command() {
      return findUserTaskCommand(userTaskCommandType.reassign);
    },

    /**
     * User Label
     * @return {string}
     */
    userLabel() {
      return "User";
    },

    /**
     * User Hint
     * @return {string}
     */
    userHint() {
      return "Select Task User";
    },

    /**
     * User Selection Required Rule(
     * @return {(function(*=))[]}
     */
    userSelectionRequired() {
      return [v => !!v || "User is required"];
    }
  },

  methods: {
    ...mapActions({
      /**
       * get Workflow Task User Candidates
       */
      getUserCandidates: "getUserCandidates"
    }),

    /**
     * Populate User List
     */
    async populateUserList() {
      try {
        if (this.task) {
          await this.getUserCandidates(this.task.taskId).then(response => {
            this.userList = response?.data;
          });

          const actorId = this.task?.actorId ?? -1;

          // Make sure whether a user has been assigned (manually/auto/self)
          if (actorId > 0) {
            // A user has been assigned (manually/auto/self)
            this.selectedUser = this.userList.find(user => user.id === actorId);
          } else {
            this.selectedUser = this.userList.find(
              user => user.name === this.task?.assignedTo
            );
          }
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * overwrite userTaskAssignmentMixin.doPerformAction()
     * @return {Promise<{type: string, message: string, outcome: string}|undefined>}
     */
    async doPerformAction() {
      const userName = this.userList?.find(
        user => user.id === this.selectedUser
      )?.name;

      return await this.performAction(
        userName,
        this.selectedUser,
        this.comment,
        this.sendNotification
      );
    }
  },

  async mounted() {
    /**
     * Populate List when component Loaded
     */
    await this.populateUserList();
  },

  watch: {
    /**
     * Watcher on Task Changed
     */
    async task() {
      await this.populateUserList();
      this.sendNotification = false;
      this.comment = "";
    }
  }
};
</script>
